import { useLocation } from "react-router-dom";
import RegionHeader from '../common/header/regionHeader';
import AlertDisplay from '../common/alerts/alerts';
import Legend from '../common/statusCards/legend';
import JsonFooter from '../common/statusCards/jsonFooter';
import StatusCards from '../common/statusCards/statusCards';
import { FillCenterContainer, MinimumMargins } from '../AppCSS';
import { useEffect, useState } from 'react';
import {
  HISTORY_JSON_URL,
  JSON_URL,
  NO_KNOWN_ISSUES,
  OUTAGE_CATEGORY,
  RegionExpandedToKey,
  categoriesForDisplay,
  showHistory
} from '../config/constants';
import { determineMostSevereStatus, generateMonthYearKeys, getTimeStamp, returnMonthYear } from '../config/helpers';
import { getIconByText, IconInterface, StatusIconText } from '../common/icons/iconObject';
import React from 'react';
import { useQueryToGetJsonFromS3 } from '../common/dataRetrieval/useAxiosWithHooks';
import { HistoryEventJson, StatusJson } from '../common/dataRetrieval/getDisplayData';
import _get from 'lodash/get';
import { ButtonGroup, ButtonGroupItem, Text } from '@amzn/storm-ui';
import EventCard from '../messageHistoryCards/eventCard';
import LoadingPage from "../common/errorPage/loadingPage";
import ErrorPage from "../common/errorPage/errorPage";
import { Category, Region, SYSTEM_WIDE_OUTAGE } from "@amzn/ads_status_dashboard_website_common";

export function StatusDashboard() {

  const historyMonthKeys = generateMonthYearKeys(6);
  const [selectedView, setSelectedView] = useState('status');
  const [activeRegionName, setActiveRegionName] = useState<Region>('NA' as Region);

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      setActiveRegionName(RegionExpandedToKey[location.state.region] as Region);
      setSelectedView(location.state.page);
    }
  }, [location.state]);

  const handleChangeViewFilter = (selection) => {
    setSelectedView(selection);
  };
  
  const isHistory = selectedView === 'history';
  const isCurrentStatus = selectedView === 'status';
  const { isSuccess: isSuccessStatus, isLoading: isStatusLoading, isError: isStatusError, data: statusDataFromQuery } = useQueryToGetJsonFromS3(JSON_URL);
  const { isSuccess: isSuccessHistory, isLoading: isHistoryLoading, isError: isHistoryError, data: historyDataFromQuery } = useQueryToGetJsonFromS3(HISTORY_JSON_URL);

  
  const statusData = React.useMemo<StatusJson[]>(() => {
    if(isSuccessStatus) {
      return statusDataFromQuery.status
    } 
  }, [statusDataFromQuery, isSuccessStatus]);
  
  const historyData = React.useMemo<HistoryEventJson[]>(() => {
    if(isSuccessHistory) {
      return historyDataFromQuery;
    }
  }, [isSuccessHistory, historyDataFromQuery]);
  
  const mostSevereIconsMapForRegion = React.useMemo<Map<Region, IconInterface>>(() => {
    if(statusData) {
      
      const newMostSevereIconsMap = new Map<Region, IconInterface>();
      //finding the mostSevereIcon for each Region, then it is further passed to RegionHeader
      Object.values(Region).forEach((region) => {
        const statusInThisRegion = statusData.filter((item) => item.region === region);
        const mostSevereStatus = determineMostSevereStatus(statusInThisRegion);
        const mostSevereIcon = getIconByText(mostSevereStatus as StatusIconText);
        newMostSevereIconsMap.set(region, mostSevereIcon);
      });
      return newMostSevereIconsMap;
    } else {
      return new Map();
    }
    
  }, [statusData]);
  
  const outageStatus = React.useMemo(() => {
    if (statusData) {
      return statusData.filter(
        (item) => item.region === Region[activeRegionName] && item.category === OUTAGE_CATEGORY
      );  
    }
  }, [statusData, activeRegionName])
  
  if(isStatusLoading || isHistoryLoading) {
    return <LoadingPage/>;
  }

  if(isStatusError || isHistoryError) {
    return <ErrorPage/>
  }

  return (
    <>
      <RegionHeader
        activeRegion={activeRegionName}
        onRegionUpdate={(returnedAlert) => setActiveRegionName(returnedAlert)}
        mostSevereIconForRegion={mostSevereIconsMapForRegion}
      />
      <MinimumMargins>
        <FillCenterContainer>
          &nbsp;&nbsp; &nbsp;&nbsp;
          {showHistory() && (
            <ButtonGroup
              name="status-history-filter"
              label="Select a view"
              selectedValue={selectedView}
              onChange={handleChangeViewFilter}
              small={false}
            >
              <ButtonGroupItem value="status">
                <Text>Current Status</Text>
              </ButtonGroupItem>
              <ButtonGroupItem value="history">
                <Text>History</Text>
              </ButtonGroupItem>
            </ButtonGroup>
          )}
          <AlertDisplay
            icon={getIconByText(_get(outageStatus, '[0].statusType', getIconByText(NO_KNOWN_ISSUES)))}
            description={_get(outageStatus, '[0].statusMessage', NO_KNOWN_ISSUES)}
          />
          <Legend timeStamp={getTimeStamp()}></Legend>
          {isCurrentStatus &&
              // eslint-disable-next-line array-callback-return
            categoriesForDisplay.map((category, index) => {
              const matchedStatus = statusData.filter(
                (item) => item.region === Region[activeRegionName] && item.category === Category[category]
              );
              if (category !== SYSTEM_WIDE_OUTAGE) {
                return (
                  <StatusCards
                    key={index}
                    regionName={activeRegionName}
                    sectionName={category as Category}
                    regionSectionStatus={matchedStatus}
                  />
                );
              }
            })}
          {isHistory &&
            historyMonthKeys.map((monthYearKey, index) => {
              const matchedStatuses = historyData.filter(
                (item) =>
                  item.region === activeRegionName && returnMonthYear(item.startTime) === monthYearKey
              );
              return (
                <EventCard
                  key={index}
                  regionName={activeRegionName}
                  monthSection={matchedStatuses}
                  monthYearKey={monthYearKey.toString()}
                />
              );
            })}
          <JsonFooter activeView={selectedView} alignItems={'center'} />
        </FillCenterContainer>
      </MinimumMargins>
      </>
  );
}
