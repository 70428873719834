import axios from 'axios';
import { useQuery } from 'react-query';

export async function useAxiosToGetJsonFromS3(url: string) {
  try {
    axios.defaults.headers.common = {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    };

    const { data: response } = await axios.get(url);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export function useQueryToGetJsonFromS3(url) {
  return useQuery(
    ['jsonFromS3', url],
    async () => {
      try {
        const { data: response } = await axios.get(url);
        return response;
      } catch (error) {
        throw error;
      }
    },
    {
      retry: false,
    }
  );
}
