import { defaultTheme } from '@amzn/storm-ui';
import { string } from 'prop-types';
import { Category, SubCategory } from '@amzn/ads_status_dashboard_website_common';

export const getBaseUrl = () => {
  if (
    window.location.hostname.toLowerCase().includes('localhost') ||
    window.location.hostname.toLowerCase().includes('dev-dsk')
  ) {
    return 'https://alpha.status.advertising.amazon.dev';
  }
  return window.location.origin;
};

export const JSON_URL = getBaseUrl() + '/status.json';
export const HISTORY_JSON_URL = getBaseUrl() + '/history.json';

export const RegionExpandedToKey: {
  // @ts-ignore
  [string]: string;
} = {
  'North and Latin America': 'NA',
  'Europe, Middle East, and North Africa': 'EU',
  'Asia Pacific': 'FE',
};

export enum StatusTypeSeverity {
  'Degraded' = 1,
  'Disrupted' = 2,
  'Informational' = 3,
  'Resolved' = 4,
}

export enum Stage {
  ALPHA = 'alpha',
  BETA = 'beta',
  PROD = 'prod',
}

export const OUTAGE_CATEGORY = 'Status announcement';

export const categoryMap: { [key in Category]: SubCategory[] } = {
  'Sponsored ads': [
    SubCategory.CampaignCreation,
    SubCategory.CampaignManagement,
    SubCategory.AdDelivery,
    SubCategory.Bulksheets,
  ],
  'Amazon DSP': [
    SubCategory.CampaignCreation,
    SubCategory.CampaignManagement,
    SubCategory.CreativeManagement,
    SubCategory.AdDelivery,
    SubCategory.Bulksheets,
  ],
  'Measurement & Reporting': [SubCategory.Reports],
  'Brand content': [SubCategory.Stores],
  'Status announcement': [SubCategory.Outage],
  'Amazon Marketing Stream': [SubCategory.Streams],
};

const newCategories = [Category.AMS];
const newSubcategories = [SubCategory.Bulksheets, SubCategory.Streams];

export const categoryMapForProduction = Object.keys(categoryMap).reduce((aggr, key) => {
  const category = key as Category;
  if (newCategories.includes(category)) {
    return aggr;
  } else {
    const subcategories = categoryMap[category].filter((subcategory) => !newSubcategories.includes(subcategory));

    return {
      ...aggr,
      [category]: subcategories,
    };
  }
}, {});

export const categoryMapForDisplay = categoryMap;

export const categoriesForDisplay = Object.keys(Category);

export const HostNameMap: { [key in Stage]: string } = {
  [Stage.ALPHA]: 'alpha.status.advertising.amazon.dev',
  [Stage.BETA]: 'beta.status.advertising.amazon.dev',
  [Stage.PROD]: 'status.ads.amazon.com',
};

export const PlatformMap = {
  'api and console': 'API and console',
  'console and api': 'API and console',
  'api only': 'API',
  'console only': 'console',
};

export const DEFAULT_ICON_TYPE = 'check';
export const DEFAULT_ICON_COLOR = defaultTheme.palette.green[700];
export const NO_KNOWN_ISSUES = 'No known issues';

export function showHistory(): boolean {
  return !window.location.href.toLowerCase().includes('preview');
}

export const DEFAULT_EVENT_HISTORY = {
  hashedId: '',
  region: '',
  category: '',
  subcategory: '',
  currentStatus: '',
  statusMessages: [],
  systemImpacted: '',
  endTime: '',
  startTime: '',
  url: '',
};
