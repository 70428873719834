import { TouchLink, Link, Text } from '@amzn/storm-ui';
import { StatusIcon } from '../common/icons/statusIcon';
import { getIconByText, StatusIconText } from '../common/icons/iconObject';
import { FlexBetweenContainer } from '../AppCSS';
import { HistoryEventJson } from '../common/dataRetrieval/getDisplayData';
import { determineMostSevereStatusV1, determineDuration } from '../config/helpers';
import { StyledCard } from './eventCard';
import { PlatformMap } from '../config/constants';
import { Region } from '@amzn/ads_status_dashboard_website_common';

export interface MonthlyDisplayProps {
  displayDivider: boolean;
  regionName: Region;
  historyEvent: HistoryEventJson;
}

function MonthlyDisplay(props: MonthlyDisplayProps) {
  const { regionName, historyEvent } = props;

  const isResolved = historyEvent.statusMessages[0].statusType === 'Resolved';
  const currStatus = isResolved ? 'Resolved:' : 'In progress:';
  const isMobile = window.screen.width < 768 || window.innerWidth < 768;


  const latestMessageDetails = historyEvent.currentMessage?
      historyEvent.currentMessage : "";
  const headerStatus = determineMostSevereStatusV1(historyEvent.statusMessages);
  const icon = isResolved
    ? getIconByText('No known issues')
    : getIconByText(historyEvent.currentStatus as StatusIconText);
  const eventHistoryLink = `${historyEvent.url}`;
  const duration = determineDuration(historyEvent.startTime, historyEvent.endTime);
  const platform = `${isResolved ? 'Affected' : 'Affects'} ${PlatformMap[historyEvent.systemImpacted.toString().toLowerCase()]}`;

  return (
    <>
      {isMobile && (
        <FlexBetweenContainer style={{ paddingBottom: '20px' }}>
          <TouchLink href={eventHistoryLink}>
            <StatusIcon
              size="sm"
              type={icon.type}
              color={icon.color}
              aria-label={icon.text}
              id={`status-${regionName}`}
              aria-hidden="true"
              style={{ padding: '2.5px', display: 'inline', alignItems: 'center', justifyContent: 'center' }}
            />
            <Text type="h4" fontSize="small" textColor="base" id={`category`} style={{ display: 'inline', paddingBottom: '60px'}}>
              <strong>{currStatus}</strong>{' '}
              {`${historyEvent.category} ${historyEvent.subcategory? historyEvent.subcategory.toLowerCase() : ""} ${headerStatus.toLowerCase()}`}
            </Text>
            <Text type="p" fontSize="small" textColor="base" id={`message`} style={{ border: '5px', paddingBottom: '6px', paddingTop: '6px' }}>
              {latestMessageDetails}
            </Text>
            <>
              <Text
                type="p"
                fontSize="small"
                textColor="base"
                id={`experience-${historyEvent.category}`}
              >
                {platform}
              </Text>
              <Text type="p" fontSize="small" textColor="base" id={`start time`}>
                {duration}
              </Text>
            </>
          </TouchLink>
        </FlexBetweenContainer>
      )}
      {!isMobile && (
        <StyledCard padding="large">
          <FlexBetweenContainer
            key={regionName}
            style={{ alignItems: 'left', display: 'flex', justifyContent: 'left' }}
          >
            <StatusIcon
              size="small"
              type={icon.type}
              color={icon.color}
              aria-label={icon.text}
              id={`status-${regionName}`}
              aria-hidden="true"
              style={{ padding: '2.5px', display: 'inline', alignItems: 'center', justifyContent: 'center' }}
            />
            <Text type="h4" fontSize="small" textColor="base" id={`category`} style={{ display: 'inline' }}>
              <strong>{currStatus}</strong>{' '}
              {`${historyEvent.category} ${historyEvent.subcategory? historyEvent.subcategory.toLowerCase() : ""} ${headerStatus.toLowerCase()}`}
            </Text>
          </FlexBetweenContainer>
          <>
            <Text type="p" fontSize="small" textColor="base" id={`message`}>
              {latestMessageDetails}
            </Text>
            <Text fontSize="small" style={{ marginBottom: '5px' }}>
              <Link href={eventHistoryLink}>See issue details</Link>
            </Text>
          </>
          <>
            <Text
              type="p"
              fontSize="small"
              textColor="base"
              id={`experience-${historyEvent.subcategory}-${historyEvent.subcategory}`}
            >
              {platform}
            </Text>
            <Text type="p" fontSize="small" textColor="base" id={`start time`}>
              {duration}
            </Text>
          </>
        </StyledCard>
      )}
    </>
  );
}

export default MonthlyDisplay;
