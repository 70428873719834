import React from 'react';
import styled from 'styled-components';
import { MinimumMargins, FillCenterContainer } from '../../AppCSS';
import { Text } from '@amzn/storm-ui';

export const ErrorMessage = styled.div`
  margin-block-start: ${({ theme }) => theme.spacing.small};
  margin-block-end: ${({ theme }) => theme.spacing.large};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ErrorText = styled(Text)`
  margin-block-end: ${({ theme }) => theme.spacing.base};
`;

function ErrorPage() {
  return (
    <>
      <MinimumMargins>
        <FillCenterContainer>
          <ErrorMessage>
            <ErrorText type="h2" fontSize="extraLarge" textColor="base" withMargin={true}>
              Sorry
            </ErrorText>
            <ErrorText type="p" fontSize="medium" textColor="secondary">
              The page you requested is not found.
            </ErrorText>
            <ErrorText type="p" fontSize="medium" textColor="secondary">
              Go to Amazon Ads' Status Dashboard <a href="/">home</a> page.
            </ErrorText>
          </ErrorMessage>
        </FillCenterContainer>
      </MinimumMargins>
    </>
  );
}

export default ErrorPage;
