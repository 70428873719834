import { Breadcrumb, BreadcrumbGroup, Text } from '@amzn/storm-ui';
import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

const BreadcrumbHeaderStyling = styled.div`
  margin-inline-start: ${({ theme }) => theme.spacing.large};
  padding-inline-start: ${({ theme }) => theme.spacing.large};
  margin-inline-end: ${({ theme }) => theme.spacing.large};
  color: floralwhite;
  margin-top: 20px;
  align-items: end;
  margin-bottom: 20px;
`;

export interface BreadcrumbHeaderProps {
  activeRegion: string;
  categorySubcategoryStatus: string;
  duration: string;
  isMobile: boolean;
}

function BreadcrumbHeader(props: BreadcrumbHeaderProps) {
  const { activeRegion, categorySubcategoryStatus, duration } = props;

  return (
    <BreadcrumbHeaderStyling>
      <BreadcrumbGroup>
        <Breadcrumb>
            <Link to={{pathname: "/", state: { region: activeRegion, page: 'status' }}}>{activeRegion}</Link>
        </Breadcrumb>
        <Breadcrumb>
            <Link to={{pathname: "/", state: { region: activeRegion, page: 'history' }}}>Status history</Link>
        </Breadcrumb>
        {!props.isMobile && <Breadcrumb label={categorySubcategoryStatus} />}
      </BreadcrumbGroup>
      <Text type="p" fontSize="large" textColor="base" style={{ fontWeight: 'bold', paddingTop: '10px' }}>
        {categorySubcategoryStatus}
      </Text>
      <Text type="p" fontSize="small" textColor="secondary" style={{ fontWeight: 'normal', paddingTop: '4px' }}>
        {duration}
      </Text>
    </BreadcrumbHeaderStyling>
  );
}

export default BreadcrumbHeader;
