import { Icon } from '@amzn/storm-ui';
import { spinner } from "@amzn/storm-ui-icons";
import { FillCenterContainer } from '../../AppCSS';

function LoadingPage() {
    return (
        <FillCenterContainer style={{ textAlign: 'center', padding: '50px' }}>
          <Icon type={spinner} />
        </FillCenterContainer>
    );
  }
  
  export default LoadingPage;