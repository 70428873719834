import { Divider, Text } from '@amzn/storm-ui';
import { StatusIcon } from '../icons/statusIcon';
import { getIconByText, IconInterface, StatusIconText } from '../icons/iconObject';
import { FlexBetweenContainer } from '../../AppCSS';
import { NO_KNOWN_ISSUES } from '../../config/constants';
import { StatusJson } from '../dataRetrieval/getDisplayData';
import { useEffect, useState } from 'react';
import {
  DEFAULT_CATEGORY,
  DEFAULT_REGION,
  DEFAULT_STATUS_MESSAGE,
  DEFAULT_SUB_CATEGORY,
  DEFAULT_SYSTEM_IMPACTED,
  DEFAULT_TIMESTAMP,
} from '../../config/errorMessages';
import React from 'react';
import { getFormattedTimestamp, getTimeStamp } from '../../config/helpers';
import { Category, Region, SubCategory } from '@amzn/ads_status_dashboard_website_common';

export interface CategoryDisplayProps {
  displayDivider: boolean;
  subCategory: SubCategory;
  regionName: Region;
  sectionName: Category;
  status: StatusJson;
}

function CategoryDisplay(props: CategoryDisplayProps) {
  const { displayDivider, subCategory, regionName, sectionName, status } = props;

  const [icon, setIcon] = useState<IconInterface>(getIconByText(NO_KNOWN_ISSUES));
  const [returnedStatus, setReturnedStatus] = useState<StatusJson>(DEFAULT_JSON);

  useEffect(() => {
    if (status) {
      const statusIcon = getIconByText(status.statusType as StatusIconText);
      setIcon(statusIcon);
      setReturnedStatus(convertResponseToStatusDisplayFormat(status));
    } else {
      setDefaultJsonValues(setReturnedStatus, setIcon);
    }
  }, [status]);

  const isNotSuccessIcon = getIconByText(icon.text as StatusIconText) !== getIconByText(NO_KNOWN_ISSUES);
  const timestamp = isNotSuccessIcon ? returnedStatus.timestamp : getTimeStamp();
  return (
    <>
      <div>
        <FlexBetweenContainer key={regionName + subCategory}>
          <Text type="h3" id={`name-${sectionName}-${subCategory}`}>
            {subCategory}
          </Text>
          <StatusIcon
            size="lg"
            type={icon.type}
            color={icon.color}
            aria-label={icon.text}
            id={`status-${subCategory}`}
            aria-hidden="true"
          />
        </FlexBetweenContainer>
        <Text type="p" fontSize="small" textColor="secondary" id={`timestamp-${sectionName}-${subCategory}`}>
          {timestamp}
        </Text>
        {isNotSuccessIcon && (
          <>
            <Text type="p" fontSize="small" textColor="secondary" id={`experience-${sectionName}-${subCategory}`}>
              Impacts {returnedStatus.systemImpacted}
            </Text>
            <Text
              fontSize="base"
              withMargin={true}
              textColor="tertiary"
              id={`statusMessage-${sectionName}-${subCategory}`}
            >
              {returnedStatus.statusMessage}
            </Text>
          </>
        )}
      </div>
      {displayDivider && <Divider data-testid="divider"></Divider>}
    </>
  );
}

export const DEFAULT_JSON = {
  category: DEFAULT_CATEGORY,
  region: DEFAULT_REGION,
  statusMessage: DEFAULT_STATUS_MESSAGE,
  statusType: NO_KNOWN_ISSUES,
  subcategory: DEFAULT_SUB_CATEGORY,
  systemImpacted: DEFAULT_SYSTEM_IMPACTED,
  timestamp: DEFAULT_TIMESTAMP,
};

function setDefaultJsonValues(
  setReturnedStatusUpdate: React.Dispatch<React.SetStateAction<StatusJson>>,
  setIcon: React.Dispatch<React.SetStateAction<IconInterface>>
) {
  setReturnedStatusUpdate(DEFAULT_JSON);
  setIcon(getIconByText(DEFAULT_JSON.statusType as StatusIconText));
}

function convertResponseToStatusDisplayFormat(status: StatusJson): StatusJson {
  return {
    category: status.category,
    region: status.region,
    statusMessage: status.statusMessage,
    statusType: status.statusType,
    subcategory: status.subcategory,
    systemImpacted: status.systemImpacted,
    timestamp: getFormattedTimestamp(new Date(status.timestamp)),
  };
}

export default CategoryDisplay;
