import adsStatusLogoPng from '../resources/amazon_ads_logo.png';
import { StyledFragment } from '../AppCSS';
import { Header, AmazonAdsLogo, HeaderText } from './header/headerCSS';

export function Page({content}) {
    return (
        <StyledFragment style={{ minHeight: '100vh' }}>
            <Header>
                <a href="https://advertising.amazon.com/">
                <AmazonAdsLogo src={adsStatusLogoPng} alt="Logo" />
                </a>
                <HeaderText fontSize="medium">Status</HeaderText>
            </Header>
            <div>{content}</div>
        </StyledFragment>
    )
}