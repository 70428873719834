import ErrorPage from './common/errorPage/errorPage';
import { Switch, Route } from 'react-router-dom';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { StatusDashboard } from './statusDashboard/StatusDashboard';
import { EventHistoryPage } from './eventHistory/EventHistoryPage';
import { Page } from './common/Page';

const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        <Route exact path="/">
          <Page content={<StatusDashboard/>} />
        </Route>
        <Route exact path="/eventHistory/:id">
          <Page content={<EventHistoryPage/>} />
        </Route>
        <Route path="*">
          <Page content={<ErrorPage/>} />
        </Route>
      </Switch>
    </QueryClientProvider>
  );
}

export default App;
