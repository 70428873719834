import { Card, CardHeader, Text } from '@amzn/storm-ui';
import MonthlyDisplay from '../messageHistoryCards/monthlyDisplay';
import styled from 'styled-components';
import { FlexBetweenContainer, StyledFragment } from '../AppCSS';
import React from 'react';
import { HistoryEventJson } from '../common/dataRetrieval/getDisplayData';
import { left } from '@popperjs/core';
import { Region } from '@amzn/ads_status_dashboard_website_common';

export const StyledCard = styled(Card)`
  margin-bottom: ${({ theme }) => theme.spacing.large};
`;

export interface EventCardProps {
  monthYearKey: string;
  regionName: Region;
  monthSection: HistoryEventJson[];
}

function EventCard(props: EventCardProps) {
  const { monthYearKey, regionName, monthSection } = props;
  const orderedMonthSection = monthSection.sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime())
  return (
    <StyledFragment>
      <CardHeader style={{ borderStyle: 'none', borderRadius: 'none', backgroundColor: '#f2f2f2', paddingLeft: '0' }}>
        <Text type="h2" textColor="base" style={{ marginLeft: '0', alignItems: left, justifyContent: left }}>
          {monthYearKey}
        </Text>
      </CardHeader>
      {orderedMonthSection.length > 0 &&
          orderedMonthSection.map((value: HistoryEventJson, key: number) => (
          <MonthlyDisplay
            key={key}
            displayDivider={false}
            regionName={regionName}
            historyEvent={value as HistoryEventJson}
          />
        ))}
      {monthSection.length === 0 && (
        <FlexBetweenContainer style={{ marginLeft: '0', borderStyle: 'none', borderRadius: 'none' }}>
          No events reported.
        </FlexBetweenContainer>
      )}
    </StyledFragment>
  );
}

export default EventCard;
