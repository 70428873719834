import { NO_KNOWN_ISSUES } from './constants';
import { getIconByText, returnMostSevereIcon, StatusIconText } from '../common/icons/iconObject';
import { StatusMessage } from '../common/dataRetrieval/getDisplayData';
/**
 * Get formatted time, which will be updated based on region in the future
 * @param time Date object with local time
 * @returns formatted local time
 */
export function getFormattedTimestamp(time: Date) {
  return time.toLocaleString('en-US', {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    hour12: true,
    minute: '2-digit',
  });
}

// Format will change based on country requesting info
export function getTimeStamp() {
  return getFormattedTimestamp(new Date());
}

export function returnMonthYear(date: string) {
  const asTimestamp = new Date(date);
  const month = asTimestamp.toLocaleString('en-US', { month: 'long' });
  const year = asTimestamp.getFullYear();
  return month + ' ' + year.toString();
}

export function determineDuration(eventStart: string, eventEnd?: string): string {
  const startDate = getFormattedTimestamp(new Date(eventStart));
  if (!eventEnd) {
    return startDate + ' - Current';
  }
  const endDate = getFormattedTimestamp(new Date(eventEnd));
  const startDateWithoutTime = startDate.split(' at ')[0];
  const [endDateWithoutTime, endTime] = endDate.split(' at ');
  if (startDateWithoutTime === endDateWithoutTime) {
    return startDate + ' - ' + endTime;
  }
  return startDate + ' - ' + endDate;
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function determineMostSevereStatus(statusMessages: any[]): string {
  return statusMessages.reduce(
    (acc, curr) => {
      if (curr.statusType && acc.statusType) {
        const mostSevereIcon = returnMostSevereIcon(
          getIconByText(acc.statusType.toString() as StatusIconText),
          getIconByText(curr.statusType.toString() as StatusIconText)
        );
        acc = mostSevereIcon.text;
      }
      return acc;
    },
    { statusType: NO_KNOWN_ISSUES.toLowerCase() }
  );
}

// TODO: Refactor this and the above function into one.
export function determineMostSevereStatusV1(statusMessages: StatusMessage[]): String {
  let mostSevereStatus: string = statusMessages[0].statusType;
  for (let i = 0; i < statusMessages.length; i++) {
    if (statusMessages[i].statusType) {
      if (statusMessages[i].statusType.toString() === 'Resolved') {
        let mostSevereIcon = returnMostSevereIcon(
          getIconByText(mostSevereStatus as StatusIconText),
          getIconByText('No known issues' as StatusIconText)
        );
        mostSevereStatus = mostSevereIcon.text;
      } else {
        let mostSevereIcon = returnMostSevereIcon(
          getIconByText(mostSevereStatus as StatusIconText),
          getIconByText(statusMessages[i].statusType.toString() as StatusIconText)
        );
        mostSevereStatus = mostSevereIcon.text;
      }
    }
  }
  if (mostSevereStatus === 'no known issues') {
    mostSevereStatus = 'Resolved';
  }
  return mostSevereStatus;
}

/**
 * We only want to show the history of the last XX days, but organized by month.
 * @returns the relevant section headers for the last X months, in reverse
 * chronological order.
 * @param numOfMonth
 */
export function generateMonthYearKeys(numOfMonth: number): string[] {
  const monthYearKeys: string[] = [];
  let currentTimestamp = new Date();
  monthYearKeys.push(returnMonthYear(currentTimestamp.toISOString()));

  for (let i = 0; i < numOfMonth - 1; i++) {
    currentTimestamp = new Date(currentTimestamp.getFullYear(), currentTimestamp.getMonth() - 1, 1);
    monthYearKeys.push(returnMonthYear(currentTimestamp.toISOString()));
  }
  return monthYearKeys;
}
