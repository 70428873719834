import { TabbedNavigationItem, TabbedNavigation } from '@amzn/storm-ui';
import { IconInterface } from '../icons/iconObject';
import React from 'react';
import { StatusIcon } from '../icons/statusIcon';
import styled from 'styled-components';
import { DEFAULT_ICON_TYPE, DEFAULT_ICON_COLOR, NO_KNOWN_ISSUES } from '../../config/constants';
import { getDefaultRegionHeader } from '../dataRetrieval/getDisplayData';
import _get from 'lodash/get';
import { Region } from '@amzn/ads_status_dashboard_website_common';

const RegionHeaderStyling = styled.div`
  margin-inline-start: ${({ theme }) => theme.spacing.small};
  background-color: #fbfbfb;
`;

const RegionHeaderBottomBarStyling = styled.div`
  height: 1px;
  background-color: #e6e9ed;
`;

export interface RegionDisplay {
  regionName: Region;
}

export interface RegionHeaderProps {
  onRegionUpdate: (regionName: Region) => void;
  activeRegion: string;
  mostSevereIconForRegion: Map<Region, IconInterface>;
}

function RegionHeader(props: RegionHeaderProps) {
  const { onRegionUpdate, mostSevereIconForRegion } = props;
  const defaultHeader = getDefaultRegionHeader();

  return (
    <>
      <RegionHeaderStyling>
        <TabbedNavigation>
          {defaultHeader.map(({ regionName }) => (
            <TabbedNavigationItem
              id={`region-${regionName}`}
              key={regionName}
              active={props.activeRegion === regionName}
              label={Region[regionName]}
              onClick={() => {
                onRegionUpdate(regionName);
              }}
              renderEnd={() => displayIconForEachRegion(mostSevereIconForRegion.get(Region[regionName]), regionName)}
            />
          ))}
        </TabbedNavigation>
      </RegionHeaderStyling>
      <RegionHeaderBottomBarStyling />
    </>
  );
}

export function displayIconForEachRegion(icon: IconInterface | undefined, regionName: Region): JSX.Element {
  return (
    <StatusIcon
      size="sm"
      type={_get(icon, 'type', DEFAULT_ICON_TYPE)}
      color={_get(icon, 'color', DEFAULT_ICON_COLOR)}
      aria-label={_get(icon, 'text', NO_KNOWN_ISSUES)}
      data-testid={regionName}
      id={`region-status-${regionName}`}
      aria-hidden="true"
    />
  );
}

export default RegionHeader;
